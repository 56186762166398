import React from 'react'
import SearchThumbs from './SearchThumbs'
import SearchAgain from './SearchAgain'
import SearchResults from './SearchResults'
import FlowerDetail from './FlowerDetail'
import Sponsors from './Sponsors'

const Home = (props) => {
    return (
        <div>
            {props.showSearchBox ?
                <SearchThumbs
                    searchOptions={props.searchOptions}
                    toggleChoice={props.toggleChoice}
                    doFlowerSearch={props.doFlowerSearch}
                    showResults={props.showResults}
                    showAdvancedSearch={props.showAdvancedSearch}
                    showAdvancedSearchCriteria={props.showAdvancedSearchCriteria}
                    showBasicSearchCriteria={props.showBasicSearchCriteria}
                />
                :
                !props.selectedFlower.id ?
                    <SearchAgain clearSearch={props.clearSearch} />
                    : null
            }

            {props.selectedFlower.id ?
                <FlowerDetail
                    hideFlowerDetail={props.hideFlowerDetail}
                    flower={props.selectedFlower}
                    favourites={props.favourites}
                    toggleFavourites={props.toggleFavourites}
                    sponsors={props.sponsors}
                    stuur={props.stuur}
                    fromRandom={props.fromRandom}
                    zoomImageView={props.zoomImageView}
                />
                : null}


            {props.showResults ?

                <SearchResults
                    flowerList={props.flowerList}
                    searchParams={props.searchParams}
                    showFlowerDetail={props.showFlowerDetail}
                />
                : null
            }

            {!props.selectedFlower.id && !props.showResults ?
                <div className="container">
                    <div className="divider"></div>
                    <div style={{"textAlign":"center"}}>
                    <Sponsors
                        sponsors={props.sponsors}
                        filterCriteria={{ "pages": "home" }}
                    />
                    </div>
                </div>
                : null}
        </div>
    )
}

export default Home