import React, { Component } from 'react'
import { BASE_URL } from './globalVars'
import FlowerDetail from './FlowerDetail'
import Sponsors from './Sponsors'
import axios from 'axios';
import SpeciesResultList from './SpeciesResultList';

class Favourites extends Component {
    state = {
        origflowerList: [],
        flowerElement: {}
    }

    componentDidMount() {
        if (this.props.flowerList.length === 0) {
            axios.get(BASE_URL + '/flowerspecies.json')
                .then(res => {
                    if (res.status === 200) {
                        let fl = res.data.filter(e => e.region !== "")
                        this.setState({
                            origflowerList: fl,
                        })
                        localStorage.setItem('flowerspecies', JSON.stringify(fl))
                    }
                })
                .catch(e => {
                    let fl = JSON.parse(localStorage.getItem('flowerspecies'))
                    this.setState({
                        origflowerList: fl,
                    })
                })
        } else {
            this.setState({
                origflowerList: this.props.flowerList,
            })
        }
    }

    filterFavourites = (arr, flowerList) => {
        let results = []
        if (arr.length > 0 && flowerList.length > 0) {
            results = flowerList.filter(i => arr.includes(i.id))
        }
        return results
    }

    showFlower = (i) => {
        let f = this.state.origflowerList
        let f2 = f.filter(e => e.id + '' === i + '')
        if (f2.length === 1) {
            this.setState({
                results: [],
                flowerElement: f2[0]
            })
        } else {
            this.setState({
                flowerElement: {}
            })
        }
    }

    componentWillUnmount() {
        axios.CancelToken.source().cancel('axios call cancelled')
    }
    render() {
        
        let favouriteSpecies = this.filterFavourites(this.props.favourites, this.state.origflowerList)
        window.scrollTo(0, 0);

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 align="left">Bookmarked list</h2>
                            <p align="left">Your favourite or bookmarked flowers are listed below for quick reference.</p>
                        </div>
                    </div>

                </div>

                {this.state.flowerElement.id ?
                    <div className="container" style={{ "paddingLeft": "0px", "paddingRight": "0px" }}>
                        <div style={{ "textAlign": "left", "paddingLeft": "15px" }}><button className="btn btn-primary" onClick={() => this.setState({ flowerElement: {} })}>Bookmarked list</button></div>
                        <div className="halfdivider"></div>
                        <FlowerDetail
                            hideBackBtn={true}
                            flower={this.state.flowerElement}
                            favourites={this.props.favourites}
                            toggleFavourites={this.props.toggleFavourites}
                            sponsors={this.props.sponsors}
                        />
                    </div>
                    :
                    <div className="container">
                        <Sponsors
                            sponsors={this.props.sponsors}
                            filterCriteria={{ "pages": "favourites" }}
                        />
                        {favouriteSpecies.map(e => {
                            return (
                                <SpeciesResultList key={e.id}
                                    favourite={e}
                                    showFlower={this.showFlower}
                                />

                            )
                        })}
                    </div>
                }

                {/* {!this.state.flowerElement.id ?
                    <div className="container">
                        <div className="divider"></div>
                        <Sponsors
                            sponsors={this.props.sponsors}
                            filterCriteria={{ "pages": "favourites" }}
                        />
                    </div>
                    : null} */}
            </div>

        )
    }

}

export default Favourites