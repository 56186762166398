import React, { Component } from 'react'
import FlowerDetail from './FlowerDetail'
import axios from 'axios';
import { BASE_URL } from './globalVars'
import Sponsors from './Sponsors'

class Show extends Component {
    state = {
        flowerNumber: '',
        flowerShow: false,
        flowerElement: {},
        origflowerList: [],
        hideFlowerDetail: true,
        resultsHeader: '',
    }

    changeHandler = (e) => {
        let { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    showFlower = (i) => {
        let ii = i;
        let f = this.state.origflowerList
        let f2 = f.filter(e => e.id + '' === ii + '')

        if (f2.length === 1) {
            this.setState({
                flowerElement: f2[0],
                resultsHeader: 'NUMBER #' + ii
            })
        } else {
            this.setState({
                flowerElement: {},
                resultsHeader: 'No result found for ' + ii
            })
        }
    }

    componentDidMount() {
        if (this.props.flowerList.length === 0) {
            axios.get(BASE_URL + '/flowerspecies.json')
                .then(res => {
                    if (res.status === 200) {
                        let fl = res.data.filter(e => e.region !== "")
                        this.setState({
                            origflowerList: fl,
                        })
                        localStorage.setItem('flowerspecies', JSON.stringify(fl))
                    }
                })
                .catch(e => {
                    let fl = JSON.parse(localStorage.getItem('flowerspecies'))
                    this.setState({
                        origflowerList: fl,
                    })
                })
        } else {
            this.setState({
                origflowerList: this.props.flowerList,
            })
        }
    }

    componentWillUnmount() {
        axios.CancelToken.source().cancel('axios call cancelled')
    }

    render() {
        window.scrollTo(0, 0);
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 align="left">Wildflower Show</h2>
                            <p align="left" style={{"fontSize":"110%"}}>Flower species are numbered at the Clanwilliam Wildflower Show and can be searched by assigned number here:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6" style={{ "textAlign": "left" }}>
                            <input
                                type="number"
                                onChange={(e) => this.changeHandler(e)}
                                name="flowerNumber"
                                min="1"
                                placeholder="Enter Flower Number"
                                value={this.state.flowerNumber}
                                className="form-control form-control-lg"
                                style={{ "width": "200px", "height":"48px","display": "inline-block", "fontSize": "150%", "verticalAlign": "middle" }}
                            /><button className="btn btn-primary" onClick={() => this.showFlower(this.state.flowerNumber)} style={{ "width": "auto" }}>Search Flower</button>


                        </div>
                    </div>
                    <div className="divider"></div>
                </div>

                {this.state.flowerElement.id ?
                    <div className="container" style={{ "paddingLeft": "0px", "paddingRight": "0px" }}>
                        <div className="line"></div>
                        <div style={{ "paddingLeft": "15px", "paddingRight": "15px" }}><h3 align="left" style={{ "fontWeight": "bold" }}>{this.state.resultsHeader}</h3></div>
                        <FlowerDetail
                            hideBackBtn={true}
                            flower={this.state.flowerElement}
                            favourites={this.props.favourites}
                            toggleFavourites={this.props.toggleFavourites}
                            sponsors={this.props.sponsors}
                        />
                    </div>
                    :
                    <div className="container">
                        <div className="line"></div>
                        <div style={{ "paddingLeft": "15px", "paddingRight": "15px" }}><h3 align="left" style={{ "fontWeight": "bold" }}>{this.state.resultsHeader}</h3></div>
                    </div>
                }

                {!this.state.flowerElement.id ?
                    <div className="container">
                        <div className="divider"></div>
                        <Sponsors
                            sponsors={this.props.sponsors}
                            filterCriteria={{ "pages": "show" }}
                        />
                    </div>
                    : null}
            </div>

        )
    }

}

export default Show