import React from 'react'

const Footer = (props) => {

    return (
        
        <div>
            <div className="divider"></div>
        </div>

    )
}

export default Footer