import React from 'react'
import Sponsors from './Sponsors'

const About = (props) => {
    window.scrollTo(0, 0);
    return (
        <div className="container" style={{ "textAlign": "left" }}>
            <div className="row">
                <div className="col-sm-6">
                    <h2>About Clanflora</h2>
                    <h3>What is it used for?</h3>
                    <p>Clanflora is an online web app specially developed to assist the wildflower enthusiast in identifying wildflowers with ease. The regions covered are the Cape West Coast, Cederberg, Nieuwoudtville and Namaqualand. We happily share the splendour of nature with every nature lover FREE of charge. </p>
                    <h3>How do I use it?</h3>
                    <p>The user can select several variables, like colour, shape, size and region to search for species on the app. Photos accompanying search results facilitate the easy identification of flowers. Information shared includes the botanical name, common name, flowering season, short description and interesting facts. Photos of the species are also displayed. The application can also be used during the Clanwilliam Wildflower Show to obtain more information on species on show.</p><p>We kick off with 600 species+ (52 Families and all but 200 Genuses) and 1 300 flower photos, which are regularly updated – truly something to look forward to! An option to download images is also available for use in areas without internet access.</p>
                    <h3>Who is Clanflora?</h3>
                    <p>Two flower and nature lovers, one with a camera (Marietjie) and the other with a PC (Jeanette), living in the flora splendour of this region.  Contact us via Social Media (@Clanflora), whatsapp (076 822 1600) or <a href="mailto:admin@clanflora.co.za" target="_blank" rel="noreferrer" style={{ "color": "#2d7e99" }}>admin@clanflora.co.za</a></p>
                    <h3>Our Mission</h3>
                    <p>Clanflora aims to create awareness for the region's unique wildflowers, the vulnerability of certain species and encourage conservation in general.</p>
                    <h3>Improvements and Suggestions</h3>
                    <p>If you have any improvement suggestions to bring you a better user experience, please send us your suggestions. If there is perhaps information missing or a certain species not listed, please point that out to us, so that it can be incorporated into the next web app release. In the meantime, find and follow us on social media.</p>
                </div>
                <div className="col-sm-6">
                    <h2>Meer omtrent Clanflora</h2>
                    <h3>Waarvoor gebruik ek die app?</h3>
                    <p>Clanflora is 'n GRATIS aanlyn toepassing (web app) wat spesiaal ontwikkel is vir die blomentoesias om veldblomme met gemak te identifiseer. Die streke wat gedek word, is die Weskus, Cederberg, Nieuwoudtville en Namakwaland.</p>
                    <h3>Hoe werk die web app?</h3>
                    <p>Die gebruiker kan deur middel van veranderlikes soos kleur, blomvorm, blomgrootte en streek die spesie op die toepassing soek en foto's wat die soekresultate vergesel maak identifikasie makliker.  Inligting wat gedeel word, is die botaniese naam, streeksnaam, blomtyd, kort beskrywing en interessante feite. Foto's van die spesie word ook vertoon.  Die toepassing sal ook gebruik kan word tydens die Clanwilliam Veldblomme Skou om meer inligting oor spesies te bekom.</p>
                    <p>
                        Ons skop af met byna 500 spesies (52 Families en bykans 200 Genusse) en 1300 blomfoto's wat gereeld bygewerk sal word. 'n Opsie om fotos af te laai is ook beskikbaar vir gebruik in gebiede sonder internettoegang.
                    </p>
                    <h3>Wie is Clanflora</h3>
                    <p>Twee blom- en natuurliefhebbers, een met 'n kamera (Marietjie) en een met 'n rekenaar (Jeanette) - woonagtig in die asemrowendste blomwêreld. Kontak ons via Sosiale Media (@Clanflora), whatsapp (076 822 1600) of per e-pos <a href="mailto:admin@clanflora.co.za" target="_blank" rel="noreferrer" style={{ "color": "#2d7e99" }}>admin@clanflora.co.za</a></p>
                    <h3>Ons Doelwit</h3>
                    <p>Clanflora stel ten doel bewusmaking van die streek se unieke blommeprag, die kwesbaarheid van sommige spesies en die aanmoediging vir bewaring. Bewonder en Bewaar ons Blommeprag.</p>
                    <h3>Voorstelle vir verbeterings/byvoegings</h3>
                    <p>Ons hoor graag van jou as blom- en natuurliefhebber vir enige voorstelle vir toekomstige gebruik.  Is daar 'n spesie wat nie gelys is nie, waaroor jy wonder?  Stuur asb foto's, ons kan dalk help met identifikasie.  Ons beoog om die spesie lys voortdurend op te dateer.  Intussen geniet die reis en volg Clanflora op sosiale media vir die nuutste toevoegings.</p>

                </div>
            </div>

            <Sponsors
                sponsors={props.sponsors}
                filterCriteria={{ "pages": "about" }}
            />

            <div className="row">
                <div className="col-md-12">
                    <h3>Bronnelys / Bedankings / Acknowledgements</h3>
                    <p>Klassifikasie van spesies</p>
                    
                        <ul>
                            <li>Clanwilliam Veldblomme Vereniging (Christo Smit, Daleen Schoombee)</li><li>
                                Cederberg Veldblomgids van Suid-Afrika 10 (Botaniese Vereniging van Suid-Afrika)</li><li>
                                Weskus Veldblomgids van Suid-Afrika 7 (Botaniese Vereniging van Suid-Afrika)</li><li>
                                Nieuwoudtville South African Wild Flowers Guide 9 (Botaniese Vereniging van Suid-Afrika)</li><li>
                                Wild flowers of Namaqualand (Annelise le Roux)</li><li>
                                Field Guide to Fynbos (John Manning)</li><li>
                                SANBI http://pza.sanbi.org/ </li><li>
                                Plantweb http://www.plantweb.co.za/start </li><li>
                                Wikipedia https://en.wikipedia.org/ </li><li>
                                Operation wildflower https://www.operationwildflower.org.za/ </li><li>
                                I spot nature https://www.ispotnature.org/</li>
                        </ul>
                    
                    <p style={{"fontSize":"100%"}}><em>"Dankie aan my geliefde Wimpie, vir geduld en hulp met fotografering van spesies. Aan Annamie vir sosiale media hantering en Sanet vir die donateursafdeling. Aan my broer De Vries, vir die inspirasie van hierdie projek en my ouers vir oneindige ondersteuning."</em> - Marietjie</p>
                </div>
            </div>
        </div>
    )
}

export default About