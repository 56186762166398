import React, { Component } from 'react'
import { decodeRegion, decodeSeasons, decodeHabitat, sanitizeImageFile } from './globalVars'
import FlowerDetailImage from './FlowerDetailImage';

const prettify = (s, params, func) => {
    s = ';' + s + ';'
    params.forEach(e => {
        s = s.replace(';' + e + ';', ';' + func(e) + ';')
    });
    let t = s.split(";")
    let ss = []
    t.forEach(tt => {
        if (tt !== '') {
            ss.push(tt)
        }
    });
    return ss.join(", ")
}

class FlowerDetail extends Component {

    render() {
        window.scrollTo(0, 0);

        let prettyRegion, prettyHabitat = ''
        prettyRegion = prettify(this.props.flower.region, ['A', 'B', 'C', 'D'], decodeRegion)
        prettyHabitat = prettify(this.props.flower.habitat, ['1', '2', '3', '4'], decodeHabitat)

        let src = [
            { flowerDetailImage1: sanitizeImageFile(this.props.flower.foto_1) },
            { flowerDetailImage2: sanitizeImageFile(this.props.flower.foto_2) },
            { flowerDetailImage3: sanitizeImageFile(this.props.flower.foto_3) },
            { flowerDetailImage4: sanitizeImageFile(this.props.flower.foto_4) },
        ]

        return (           
                
                <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        {this.props.hideBackBtn !== true ?
                            <div align="left">
                                <div className="divider"></div>
                                {this.props.fromRandom ?
null
                                    :
                                <button className="btn btn-primary" onClick={() => this.props.hideFlowerDetail()}
                                >Back to results
                                </button>
    }
                                
                                {this.props.favourites.includes(this.props.flower.id) ?
                                    <button className="btn btn-secondary" onClick={() => this.props.toggleFavourites(this.props.flower.id)}>
                                        <i className="fas fa-bookmark"></i> Bookmarked
                                    </button>
                                    :
                                    <button className="btn btn-secondary btn-grey" onClick={() => this.props.toggleFavourites(this.props.flower.id)}>
                                        <i className="far fa-bookmark"></i> Bookmark
                                    </button>
                                }

                            </div>
                            : <div align="left">
                                {this.props.favourites.includes(this.props.flower.id) ?
                                    <button className="btn btn-secondary" onClick={() => this.props.toggleFavourites(this.props.flower.id)}>
                                        <i className="fas fa-bookmark"></i> Bookmarked
                                    </button>
                                    :
                                    <button className="btn btn-secondary btn-grey" onClick={() => this.props.toggleFavourites(this.props.flower.id)}>
                                        <i className="far fa-bookmark"></i> Bookmark
                                    </button>
                                }
                            </div>

                        }
                    </div>

                </div>
                <div className="row">
                    {/* <div className='col-sm-6'> */}
                        {/* <Sponsors
                            sponsors={this.props.sponsors}
                            filterCriteria={{ "flowerspecies": this.props.flower.id+"" }}
                        /> */}
                    {/* </div> */}

                    <div className="col-sm-6" style={{"paddingTop":"0"}}>

                    <h3 align="left" style={{"lineHeight":"1"}}><strong>#{this.props.flower.id}</strong></h3>
                        <h3 align="left"><strong>Family Botanical Name</strong><br /><em>{this.props.flower.family_botanical_name}</em></h3>

                        <h4 align="left"><strong>Family Common Names</strong><br />{this.props.flower.family_common_name} <em>{this.props.flower.familie_noemnaam}</em></h4>
                        <h4 align="left"><strong>Genus Botanical Name</strong><br /><em>{this.props.flower.genus_botanical_name ? this.props.flower.genus_botanical_name : '--'}</em></h4>
                        <h4 align="left"><strong>Genus Vernacular Name</strong><br /><em>{this.props.flower.genus_vernacular_name ? this.props.flower.genus_vernacular_name : '--'}</em></h4>
                        <h4 align="left"><strong>Species Botanical Name</strong><br /><em>{this.props.flower.species_botanical_name}</em></h4>
                        <h4 align="left"><strong>Species Vernacular Name</strong><br /><em>{this.props.flower.species_vernacular_name}</em></h4>
                        <h4 align="left"><strong>SANBI Red list</strong><br /><em>{this.props.flower.sanbi_redlist}</em></h4>
                    </div>
                    <div className="col-sm-6">
                        <div className="divider"></div>

                        <p align="left">
                            {this.props.flower.main_color.split(";").map(e => {
                                return <img key={e} src={"/choice_icons/" + e.replace(" ", "").toLowerCase() + ".jpg"} alt={this.props.flower.main_color} className="midiThumbnailImage" />
                            })}
{this.props.flower.flower_size.split(";").map(e => {
                                return <img key={e} src={"/choice_icons/" + e.toLowerCase() + ".jpg"} alt={this.props.flower.flower_size} className="midiThumbnailImage" />
                            })}

{this.props.flower.flower_shape.split(";").map(e => {
                                return <img key={e} src={"/choice_icons/" + e.replace(" ", "").toLowerCase() + ".jpg"} alt={this.props.flower.flower_shape} className="midiThumbnailImage" />
                            })}

{this.props.flower.leaf_shape.split(";").map(e => {
                                return <img key={e} src={"/choice_icons/" + e.toLowerCase() + ".jpg"} alt={this.props.flower.leaf_shape} className="midiThumbnailImage" />
                            })}

{this.props.flower.plant_height.split(";").map(e => {
                                return <img key={e} src={"/choice_icons/" + e.toLowerCase() + ".jpg"} alt={this.props.flower.plant_height} className="midiThumbnailImage" />
                            })}

                        </p>
                        <h4 align="left"><strong>Flowering Season</strong><br /><em>{decodeSeasons(this.props.flower.season)}</em></h4>
                        <h4 align="left"><strong>Habitat</strong><br /><em>{prettyHabitat}</em></h4>
                        <h4 align="left"><strong>Region</strong><br /><em>{prettyRegion}</em></h4>
                        <h4 align="left"><strong>Endemic</strong><br /><em>{this.props.flower.endemic}</em></h4>
                        <h4 align="left"><strong>Description</strong><br /><em>{this.props.flower.description}</em></h4>
                        <h4 align="left"><strong>Beskrywing</strong><br /><em>{this.props.flower.beskrywing}</em></h4>
                    </div>

                </div>

                {this.props.flower.interessante_inligting !== '' ?

                    <div className="row">
                        <div className="col-sm-12">
                            <h4 align="left"><strong>Interesting information / interessante inligting</strong></h4>
                            <p align="left"><em>{this.props.flower.interessante_inligting}</em></p>
                        </div>
                    </div>

                    : null}

                <div className="row">
                    <div className="col-sm-3">
                        <FlowerDetailImage imgUrl={src[0].flowerDetailImage1} altText={this.props.flower.family_botanical_name} />
                    </div>
                    <div className="col-sm-3">
                        <FlowerDetailImage imgUrl={src[1].flowerDetailImage2} altText={this.props.flower.family_botanical_name} />
                    </div>
                    <div className="col-sm-3">
                        <FlowerDetailImage imgUrl={src[2].flowerDetailImage3} altText={this.props.flower.family_botanical_name} />
                    </div>
                    <div className="col-sm-3">
                        <FlowerDetailImage imgUrl={src[3].flowerDetailImage4} altText={this.props.flower.family_botanical_name} />
                    </div>
                </div>
                <div className="divider"></div>

                {/* <Sponsors
                    sponsors={this.props.sponsors}
                    filterCriteria={{ "flowerspecies": this.props.flower.id+"" }}
                />
                <div className="divider"></div> */}
            </div>
                


            

        )
    }

}

export default FlowerDetail