import React from 'react'
import ThumbnailImage from './ThumbnailImage'

const ThumbnailList = (props) => {

    return (
        <div>
            {props.searchOptions.map(e => {
                return (
                    <ThumbnailImage
                        key={e.listId}
                        list={e}
                        toggleChoice={props.toggleChoice}
                    />
                )
            })}
        </div>
    )
}

export default ThumbnailList