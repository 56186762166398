import React from 'react'
import ThumbnailList from './ThumbnailList'

const SearchThumbs = (props) => {
    let list1 = props.searchOptions.filter(e => e.listId === 1)
    let list2 = props.searchOptions.filter(e => e.listId === 2)
    let list3 = []
    let list4 = []
    let list5 = []
    let list6 = []
    
    if (props.showAdvancedSearch) {
        list3 = props.searchOptions.filter(e => e.listId === 3)
        list4 = props.searchOptions.filter(e => e.listId === 4)
        list5 = props.searchOptions.filter(e => e.listId === 5)
        list6 = props.searchOptions.filter(e => e.listId === 6)
    }

    if (props.showResults) {
        window.scrollTo(0, 0);
    }

    return (
        <div className="subnavbar">
            <div className="divider"></div>
            <div id="searchCriteria">
                <div className="container">
                <button className="btn btn-primary"
                                onClick={() => props.doFlowerSearch()}
                            >
                                <i className="fas fa-search" style={{"fontSize":"90%"}}></i> Search
                            </button>
                    <div className="row">
                        <div className="thumbsBlock">{thumbsDiv(list1, props.toggleChoice)}</div>
                        <div className="thumbsBlock">{thumbsDiv(list2, props.toggleChoice)}</div>
                        {list3 !== [] ?
                            <div className="thumbsBlock">{thumbsDiv(list3, props.toggleChoice)}</div>
                            : null
                        }
                        {list4 !== [] ?
                            <div className="thumbsBlock">{thumbsDiv(list4, props.toggleChoice)}</div>
                            : null
                        }
                        {list5 !== [] ?
                            <div className="thumbsBlock">{thumbsDiv(list5, props.toggleChoice)}</div>
                            : null
                        }
                        {list6 !== [] ?
                            <div className="thumbsBlock">{thumbsDiv(list6, props.toggleChoice)}</div>
                            : null
                        }
                    </div>
                    <div>
                    <button className="btn btn-primary"
                                onClick={() => props.doFlowerSearch()}
                            >
                                <i className="fas fa-search" style={{"fontSize":"90%"}}></i> Search
                            </button>&nbsp;
                            {!props.showAdvancedSearch ?
                                <button className="btn btn-secondary"
                                    onClick={() => props.showAdvancedSearchCriteria()}
                                >
                                    <i className="fas fa-flask" style={{"fontSize":"90%"}}></i> More criteria
                            </button>
                                : 
                                <button className="btn btn-secondary"
                                        onClick={() => props.showBasicSearchCriteria()}
                                    >
                                        <i className="fas fa-flask" style={{"fontSize":"90%"}}></i> Less criteria
                                    </button>
                                
                        }
                    </div>
                </div>
            </div>
            
        </div>


    )
}

const thumbsDiv = (list, func) => {
    return (<div className="row">
        <ThumbnailList
            searchOptions={list}
            toggleChoice={func}
        />
    </div>)

}

export default SearchThumbs