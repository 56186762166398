import React from 'react'

const FlowerDetailImage = (props) => {
    return (
        props.imgUrl!== '' ? 
            <img src={'/flowerstock/' + props.imgUrl}
                alt={props.altText}
                className="flowerDetailImage"
            />
            :
            null
    )
}

export default FlowerDetailImage;