import { sanitizeImageFile } from "./globalVars";

const RandomFlower = (props) => {
  const foto = sanitizeImageFile(
    props.flowerList[props.randomFlower.id ?? 1].foto_1
  );
  return (
    <div
      className="randomFlowerBg"
      style={{ maxWidth: "100%", minHeight: "100vh", marginTop: "-90px" }}
    >
      <div style={{ height: "10px" }}></div>
      <img
        src="/logo512alt.png"
        alt="Clanflora"
        style={{ width: "60%", maxWidth: "360px" }}
      />
      <div style={{ height: "10px" }}></div>
      <div>
        <button
          className="btn btn-primary"
          style={{
            fontSize: "200%",
            width: "100%",
            height: "60px",
            backgroundColor: "#F99D38",
          }}
          onClick={() => props.toggleRandomFlower()}
        >
          <i className="fas fa-search" style={{ fontSize: "90%" }}></i> Search
          flowers
        </button>
      </div>
      <img
        src={"flowerstock/" + foto}
        alt={props.flowerList[props.randomFlower.id ?? 1].family_botanical_name}
        style={{ width: "100%", maxWidth: "500px" }}
      />
      <div style={{ height: "15px" }}></div>
      <div style={{ fontSize: "180%", padding: "2px" }}>
        {props.flowerList[props.randomFlower.id ?? 1].family_botanical_name}
      </div>
      <div style={{ fontSize: "110%" }}>
        {props.flowerList[props.randomFlower.id ?? 1].family_common_name}{" "}
        <em>{props.flowerList[props.randomFlower.id ?? 1].familie_noemnaam}</em>
      </div>
      <div style={{ height: "15px" }}></div>
      <div>
        <button
          className="btn btn-info"
          onClick={() => {
            props.showRandomFlowerDetail(
              props.flowerList[props.randomFlower.id ?? 1]
            );
          }}
        >
          <i className="fas fa-search" style={{ fontSize: "90%" }}></i> See
          flower detail
        </button>
      </div>
      <div style={{ height: "25px" }}></div>
      <div style={{ height: "25px" }}></div>
    </div>
  );
};

export default RandomFlower;
