
import React from 'react'
import { BASE_URL } from './globalVars'
import { sanitizeImageFile } from './globalVars'

const SpeciesResultList = (props) => {
    const foto1 = sanitizeImageFile(props.favourite.foto_1);
    return (
        <div style={{"width":"49.7%","display":"inline-block","fontSize":"130%","verticalAlign":"top"}}>
                <img  onClick={() => props.showFlower(props.favourite.id)} src={BASE_URL + '/flowerstock/' + foto1} alt={props.favourite.family_botanical_name} style={{"width":"100%","border":"3px #ffffff solid"}} />
                    <div onClick={() => props.showFlower(props.favourite.id)} style={{"minHeight":"90px","padding":"3px"}}>
                    <strong>#{props.favourite.id}</strong> {props.favourite.genus_botanical_name} - {props.favourite.genus_vernacular_name}, <em>{props.favourite.species_botanical_name} {props.favourite.species_vernacular_name}</em>
                    </div>

        </div>
    )

}

export default SpeciesResultList

