const BASE_URL = '';
const GESK_URL = 'gesk'

const decodeRegion = (r) => {
    switch (r.toLowerCase()) {
        case 'a':
            return 'West Coast'
 
        case 'b':
            return 'Cederberg'

        case 'c':
            return 'Nieuwoudtville'
   
        case 'd':
            return 'Namaqualand'

        default:
            return ''
    }
}

const decodeSeasons = (s) => {
    let ss = "";
    let arr = s.split(";");
    arr.forEach(e => {
        ss = ss + ' ' + (e);
    });
    return ss;
}

const decodeHabitat = (h) => {
    switch (h) {
        case '1':
            return 'Clay soil'
 
        case '2':
            return 'Sandy soil'

        case '3':
            return 'Rocky areas'
   
        case '4':
            return 'Wet areas'

        default:
            return ''
    }
}

const matchElement = (element, criteria, strict=true) => {
    let criteriaKeys = Object.keys(criteria)
    let matchesArray = []
    
    criteriaKeys.forEach(ck => {
        let elementValue = element[ck]+''
        elementValue = elementValue.toLowerCase()
        let criteriaValuesArray = criteria[ck]

        if (strict === true) {
            if (elementValue.includes(";")) {
                if (elementValue.includes(criteriaValuesArray.join("").toLowerCase())) {
                    matchesArray.push(1)
                } else {
                    matchesArray.push(0)
                }
            } else {
                if (criteriaValuesArray.includes(elementValue)) {
                    matchesArray.push(1)
                } else {
                    matchesArray.push(0)
                }
            }
        } else {
            if (elementValue.includes(criteriaValuesArray.join("").toLowerCase())) {
                matchesArray.push(1)
            }    
        }
        
    })
    
    if (strict === true) {
        let totalMatches = matchesArray.reduce((a,b) => a+b)
        return (criteriaKeys.length === totalMatches)
    } else {
        return (matchesArray.length > 0)
    }
    
}

const doSearchOnList = (flowerlist, criteria, strict=true) => {
    let alteredCriteria = {}
    let resultArray = []

    criteria.forEach(i => {
        let k = i[0]
        let v = i[1].title

        if (alteredCriteria[k]) {
            let temp = alteredCriteria[k]
            alteredCriteria[k] = temp.concat(v)
        } else {
            alteredCriteria[k] = [v]
        }
    });
    
    flowerlist.forEach(f => {
        if (matchElement(f,alteredCriteria, strict)) {
            resultArray.push(f)
        }
    });
    return resultArray
}

const sanitizeString = (str) => {
    str = str.replace(/[^a-z0-9 .,_-]/gim,"");
    return str.trim();
}

const sanitizeImageFile = (img) => {
    let f = img ?? "";
    if (f) {
        f = f.replace(".jpg", "");
        f = f.replace(".JPG", "");
        f = f.replace("  ", " ");
    }
    return f ? f + '.jpg' : "";
}

module.exports = {
    BASE_URL,
    GESK_URL,
    decodeRegion,
    decodeSeasons,
    decodeHabitat,
    doSearchOnList,
    matchElement,
    sanitizeString,
    sanitizeImageFile
}