
import React from 'react'

const SearchResultThumbnails = (props) => {

    return (
        props.resultsThumbs.map(e => {
            return (
                <img
                    key={e}
                    src={"/choice_icons/" + e + ".jpg"}
                    alt={e}
                    className="miniThumbnailImage"
                />
            )
        })
    )

}
export default SearchResultThumbnails