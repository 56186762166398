import React, { Component } from 'react'
import { BASE_URL, sanitizeImageFile } from './globalVars'

class Flower extends Component {
    state = {
        thumbnailImage: 'clanflora-thumbnail.jpg'
    }

    render() {
        const foto1 = sanitizeImageFile(this.props.info.foto_1);
        
        return (

            <div style={{"width":"49.7%","display":"inline-block","fontSize":"130%","verticalAlign":"top"}}>
                <img src={BASE_URL+'/flowerstock/'+foto1} alt={this.props.info.family_botanical_name} className="flowerImage"
                    onClick={() => this.props.showFlowerDetail(this.props.info)}
                />
                {/* <h5><strong>{this.props.info.family_botanical_name} {this.props.info.genus_botanical_name}</strong></h5>
                <h5>{this.props.info.family_common_name} <em>{this.props.info.familie_noemnaam}</em></h5> */}
                <div style={{"minHeight":"50px","padding":"3px"}}>
                    <strong>#{this.props.info.id}</strong> {this.props.info.genus_botanical_name} - <em>{this.props.info.species_botanical_name}</em>
                    </div>
            </div>
        )
    }

}

export default Flower