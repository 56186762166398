
import React from 'react'
import { Link } from 'react-router-dom'

const Menu = (props) => {
    
    let m = [

        {link: '/favourites', title: 'Bookmarked List'},
        {link: '/text',title: 'Search: Name(s)'},
        {link: '/show',title: 'Search: Flower Show Nr'},
        {link: '/search',title: 'How search works'},
        {link: 'https://www.clanflora.co.za', type: 'sm', title: 'About Clanflora'},
        {link: 'https://www.facebook.com/Clan-Flora-104878797822734/?tn-str=k%2AF', type: 'sm', title: 'facebook', icon: 'fab fa-facebook-square'},
        {link: 'https://www.instagram.com/clanflora', type: 'sm', title: 'Instagram', icon: 'fab fa-instagram-square'},
        {link: 'https://wa.me/27768221600', type: 'sm', title: 'WhatsApp', icon: 'fab fa-whatsapp-square'},
        {link: 'mailto:admin@clanflora.co.za', type: 'sm', title: 'E-mail', icon: 'fas fa-envelope-square'},
    ]
    return (
        <div className="row" style={{"minHeight":"100vh"}}>
            <div className="divider"></div>
              <div style={{"minHeight":"80vh"}}>
              <div className="line"></div>
              <div style={{"paddingTop":"15px", "paddingBottom":"15px"}}><Link to="/" onClick={() => props.clearSearch()} className="menuLink">Home</Link></div>
              
              {m.map(e => {
                  return (
                      <div key={e.link}>
                        <div className="line"></div>
                        <div style={{"paddingTop":"15px", "paddingBottom":"15px"}}>
                            {e.type==='sm'?
                                <a target={"_blank"} href={e.link} rel="noreferrer" className="menuLink" onClick={()=>props.closeMenu()} style={{"letterSpacing":"2px"}}>
                                <div><i className={e.icon}></i> {e.title}</div>
                                </a>
                            : <Link to={e.link} className="menuLink" onClick={()=>props.closeMenu()} style={{"letterSpacing":"2px"}}>{e.title}</Link>
                            }
                        </div>
                    </div>
                  )
                  
              })}
              </div>
            <div style={{"paddingTop":"15px", "paddingBottom":"15px","color":"#ffffff"}}>
                &copy; Clanflora 2022
                </div>
          </div>
    )
}

export default Menu