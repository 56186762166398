import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './Home'
import Navbar from './Navbar'
import Search from './Search'
import Footer from './Footer'
import About from './About'
import Contact from './Contact'
import Favourites from './Favourites'
import Show from './Show'
import TextSearch from './TextSearch'
import { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from './globalVars'
import RandomFlower from './RandomFlower';
import ZoomImage from './ZoomImage';
// import FlowerList from './FlowerList'

class App extends Component {

  state = {
    showMenu: false,
    searchOptions: [],
    flowerList: [],
    favourites: [],
    resultsList: [],
    searchParams: [],
    showSearchBox: true,
    showResults: false,
    selectedFlower: {},
    showAdvancedSearch: false,
    sponsors: [],
    fromRandom: false,
    randomFlower: {id: Math.round((Math.random() * 533))},
    zoomImage:''
  }

  toggleChoice = (listId, e) => {
    //console.log(listId, e)
    let newOptions = this.state.searchOptions
    let newElement = { id: e.id, title: e.title, checked: (!e.checked) }
    newOptions[listId - 1].listOptions[e.id - 1] = newElement

    let s = []

    newOptions.forEach(e => {

        e.listOptions.forEach(f => {
          if (f.checked) {
            s.push([e.listAttribute, f])
          }
        })
    })

    this.setState({
      searchOptions: newOptions,
      searchParams: s
    })

  }

  doFlowerSearch = () => {
    this.setState({
      showSearchBox: false,
      showResults: true
    })
  }

  clearSearch = () => {
    window.scrollTo(0, 0);
    this.setState({
      searchOptions: JSON.parse(localStorage.getItem('searchOptions')),
      showSearchBox: true,
      showResults: false,
      searchParams: [],
      selectedFlower: {},
      showMenu: false
    })
  }
  showAdvancedSearchCriteria = () => {
    this.setState({
      showAdvancedSearch: true,
    })
  }
  showBasicSearchCriteria = () => {
    this.setState({
      showAdvancedSearch: false,
    })
  }

  showFlowerDetail = (i) => {
    this.setState({
      selectedFlower: i,
      fromRandom: false,
      showResults: false
    })
  }

  showRandomFlowerDetail = (i) => {
    this.setState({
      selectedFlower: i,
      randomFlower: {},
      showResults: false,
      showSearchBox: false,
      fromRandom: true
    })
  }

  hideFlowerDetail = () => {
    this.setState({
      selectedFlower: {},
      showResults: true
    })
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  closeMenu = () => {
    if (this.state.showMenu) {
      this.setState({
        showMenu: false
      })
    }
  }

  loadRemoteResources = () => {
    axios.get(BASE_URL + '/searchChoices.json')
      .then(res => {
        if (res.status === 200) {
          this.setState({
            searchOptions: res.data,
            
          })
          localStorage.setItem('searchOptions', JSON.stringify(res.data))
        }
      })
      .catch(e => {
        let s = JSON.parse(localStorage.getItem('searchOptions'))
        this.setState({
          searchOptions: s,
          
        })
      })

    axios.get(BASE_URL + '/flowerspecies2024.json')
      .then(res => {
        if (res.status === 200) {
          let fl = res.data.filter(e => e.region !== "")
          this.setState({
            flowerList: fl,
            
          })
          localStorage.setItem('flowerspecies', JSON.stringify(fl))
        }
      })
      .catch(e => {
        let fl = JSON.parse(localStorage.getItem('flowerspecies'))
        this.setState({
          flowerList: fl,
          
        })
      })

      // axios.get(BASE_URL + '/sponsors2024.json')
      // .then(res => {
      //   if (res.status === 200) {
      //     let fl = res.data
      //     this.setState({
      //       sponsors: fl,
            
      //     })
      //     localStorage.setItem('sponsors', JSON.stringify(fl))
      //   }
      // })
      // .catch(e => {
      //   let fl = JSON.parse(localStorage.getItem('sponsors'))
      //   this.setState({
      //     sponsors: fl,
          
      //   })
      // })
  }

  toggleFavourites = (flowerId) => {
    let s = this.state.favourites
    if (s.includes(flowerId)) {
      let index = s.indexOf(flowerId);
      if (index !== -1) {
        s.splice(index, 1);
      }
    } else {
      s.push(flowerId)
    }
    
    let s2 = [...new Set(s)]
    this.setState({
      favourites: s2
    })
    localStorage.setItem('myFavourites', JSON.stringify(s2))
  }

  componentDidMount() {
    this.loadRemoteResources()
    let fav = JSON.parse(localStorage.getItem('myFavourites'))
    if (fav) {
      this.setState({
        favourites: fav,
      })
    }
  }

  componentWillUnmount() {
    axios.CancelToken.source().cancel('axios call cancelled')
    this.stopIntervals()
  }

  toggleRandomFlower = () => {
    this.setState({
      randomFlower: {},
    })
  }

  stopIntervals() {
    for (let i = 0; i < 100; i++) {
      window.clearInterval(i);
    }
  }

  zoomImageView = (imgSrc) => {
    console.log(imgSrc)
    this.setState({
        zoomImage: imgSrc
    })
  }
  zoomImageClear = () => {
    this.setState({
        zoomImage: ''
    })
  }
  render() {
    return (
      <div className="App">
{/* <FlowerList flowerList={this.state.flowerList}    /> */}

        {this.state.zoomImage ?
        <div>
          <ZoomImage zoomImage={this.state.zoomImage} zoomImageClear={this.zoomImageClear} />
          </div>
          :
        <Router>
          
          {(this.state.flowerList.length > 0 && this.state.randomFlower.id > 0)?
            <RandomFlower 
            toggleRandomFlower={this.toggleRandomFlower} 
            showRandomFlowerDetail={this.showRandomFlowerDetail}
            clearSearch={this.clearSearch}
            flowerList={this.state.flowerList}
            randomFlower={this.state.randomFlower}
            />
            : 
            <Navbar showMenu={this.state.showMenu} 
            clearSearch={this.clearSearch} 
            toggleMenu={this.toggleMenu} 
            closeMenu={this.closeMenu} 
            downloadAvailable={(this.state.flowerList.length > 0)}
            speciesCount={this.state.flowerList.length}
          />
          }
          
          {(this.state.randomFlower.id) ?
            null
            :

            <Switch>

            <Route path="/contact" exact>
              <Contact sponsors={this.state.sponsors}   />
            </Route>

            <Route path="/favourites" exact>
              <Favourites 
                flowerList={this.state.flowerList} 
                sponsors={this.state.sponsors} 
                favourites={this.state.favourites}
                toggleFavourites={this.toggleFavourites} 
                
                />
            </Route>

            <Route path="/show" exact>
              <Show 
                flowerList={this.state.flowerList} 
                sponsors={this.state.sponsors} 
                favourites={this.state.favourites}
                toggleFavourites={this.toggleFavourites}
                
              />
            </Route>

            <Route path="/text" exact>
              <TextSearch 
                flowerList={this.state.flowerList} 
                sponsors={this.state.sponsors} 
                favourites={this.state.favourites}
                toggleFavourites={this.toggleFavourites}
                
              />
            </Route>

            <Route path="/about" exact>
              <About sponsors={this.state.sponsors}   />
            </Route>

            <Route path="/search" exact>
              <Search sponsors={this.state.sponsors}   />
            </Route>

            {/* {this.state.flowerList.length > 0?
              <Route path="/download" exact>
                <Download flowerList={this.state.flowerList} loadRemoteResources={this.loadRemoteResources}
                sponsors={this.state.sponsors} searchOptions={this.state.searchOptions}  />
              </Route>
              : null } */}

            <Route path="/">
              <Home 
                  showSearchBox={this.state.showSearchBox}
                  showAdvancedSearch={this.state.showAdvancedSearch}
                  selectedFlower={this.state.selectedFlower}
                  fromRandom={this.state.fromRandom}
                  searchOptions={this.state.searchOptions}
                  toggleChoice={this.toggleChoice}
                  doFlowerSearch={this.doFlowerSearch}
                  showResults={this.state.showResults}
                  clearSearch={this.clearSearch}
                  hideFlowerDetail={this.hideFlowerDetail}
                  zoomImageView={this.zoomImageView}
                  flower={this.state.selectedFlower}
                  sponsors={this.state.sponsors} 
                  flowerList={this.state.flowerList}
                  searchParams={this.state.searchParams}
                  showFlowerDetail={this.showFlowerDetail}
                  showAdvancedSearchCriteria={this.showAdvancedSearchCriteria}
                  showBasicSearchCriteria={this.showBasicSearchCriteria}
                  favourites={this.state.favourites}
                  toggleFavourites={this.toggleFavourites}
                  
              />
              
            </Route>

            
          </Switch>

          }



          <Footer searchOptions={this.state.searchOptions} sponsors={this.state.sponsors} />
        </Router>

        }

        
      </div>
    );
  }

}

export default App;
