import React from 'react'

const SearchAgain = (props) => {

    return (
        <div className="subnavbar">
            <div className="divider"></div>
            <button
                className="btn btn-primary"
                onClick={() => props.clearSearch()}
            >Search again
          </button>
        </div>
    )
}
export default SearchAgain