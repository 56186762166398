import React, { Component } from 'react'

class Advert extends Component {
    state = {
        showAdDetails: false,
        adImg:"/sponsorstock/" + this.props.sponsor.advert.img
    }

    render() {

        return (
            this.props.columnSize === 3 ?
                <div className="col-sm-12" style={{ "textAlign": "center" }}>
                    <div style={{ "padding": "3px" }}>

                        <img
                            src={this.state.adImg}
                            alt={this.props.sponsor.sponsor_business_name}
                            className="advertImage"
                            onClick={() => this.setState({ showAdDetails: !this.state.showAdDetails })}
                        />

                        {!this.state.showAdDetails ?

                            null
                            :

                            <div style={{ "padding": "10px", "backgroundColor": "#efefef" }}>
                                <div style={{ "padding": "5px","textAlign":"center" }}><strong>{this.props.sponsor.sponsor_business_name.toUpperCase()}</strong></div>
                                <div style={{ "padding": "5px" }}><strong><i className="fas fa-envelope"></i> {this.props.sponsor.advert.email}</strong>
                                </div>
                                <div style={{ "padding": "5px" }}>
                                    <strong><i className="fas fa-phone"></i> {this.props.sponsor.advert.tel}</strong>

                                </div>
                                <div style={{ "padding": "5px" }}><strong><i className="fab fa-chrome"></i></strong> 
                                    {this.props.sponsor.advert.url ? 
                                     <a href={this.props.sponsor.advert.url} target="_blank" rel="noreferrer"> {this.props.sponsor.advert.url}</a>
                                    : "--"}
                                </div>
                            </div>
                        }

                    </div>

                </div>
                :
                <div>
                    {this.state.adImg !== ''?
                    <div className="col-sm-6" style={{ "textAlign": "center" }}>
                        <div>
                        <img
                            src={this.state.adImg}
                            alt={this.props.sponsor.sponsor_business_name}
                            className="advertImage"
                            onClick={() => this.setState({ showAdDetails: !this.state.showAdDetails })}
                        />

                            {!this.state.showAdDetails ?

                            null
                            :

                            <div style={{ "padding": "10px", "backgroundColor": "#efefef","fontSize":"160%" }}>
                                <div style={{ "padding": "5px" }}><strong>{this.props.sponsor.sponsor_business_name}</strong></div>
                                <div style={{ "padding": "5px" }}><strong><i className="fas fa-envelope"></i> {this.props.sponsor.advert.email}</strong>
                                </div>
                                <div style={{ "padding": "5px" }}>
                                    <strong><i className="fas fa-phone"></i> {this.props.sponsor.advert.tel}</strong>

                                </div>
                                <div style={{ "padding": "5px" }}><strong><i className="fab fa-chrome"></i></strong> 
                                    {this.props.sponsor.advert.url ? 
                                    <a href={"https://www.clanflora.co.za/_sponsors/trx.php?applink="+this.props.sponsor.advert.url} target="_blank" rel="noreferrer" 
                                    > {this.props.sponsor.advert.url}</a>
                                    : "--"}
                                </div>
                            </div>
                            }
                            
                        </div>
                        <div className="divider"></div>
                    </div>
: null}
                </div>

        )
    }



}
export default Advert