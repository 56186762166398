import React from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'

const Navbar = (props) => {
  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="row">
          <div className="col10" style={{"fontFamily":"arial","letterSpacing":"normal","textAlign":"center","color":"#ffffff","fontSize":"15px","paddingLeft":"10px"}}>
            <i className="fas fa-seedling"></i> <strong>{props.speciesCount}</strong>
            </div>
          <div className="col80"><Link to="/" onClick={() => props.clearSearch()}><img src="/logo512.png" alt="Clanflora" className="navLogo" /></Link></div>
          <div className="col10" style={{ "verticalAlign": "middle", "textAlign": "left" }}>
            <button className="btn btn-secondary" onClick={() => props.toggleMenu()} style={{"backgroundColor":"transparent","color":"#ffffff","fontSize":"20px"}}>
              {props.showMenu ?
                <i className="fas fa-window-close"></i>
                :
                <i className="fas fa-bars"></i>
              }
            </button>
          </div>
        </div>

      </div>
      {props.showMenu ?
          <Menu closeMenu={props.closeMenu} downloadAvailable={props.downloadAvailable} clearSearch={props.clearSearch} />
          : null
        }
    </nav>
  )
}

export default Navbar