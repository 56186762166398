import React from 'react'

const Search = () => {
    return (
        <div className="container" style={{"textAlign":"left"}}>
            <div className="row">
                <div className="col-sm-12">
                    <h2>How to search and find</h2>
                    <h3>Basic / advanced thumbnail search</h3>
                    <p>When selecting more than one option in a group, the results will contain matches from option 1 OR option 2 for instance.
                        </p><p>Searching yellow and orange, would result in a list of species containing either of the colours. </p>
                    <p>Selecting from more than one criteria group will narrow down your search -  for example, selecting yellow and the Nieuwoudtville region, will result in matches of yellow flowers within that region.</p>
                    <h3>Search by number</h3>
                    <p>If you are privileged to be experiencing the Clanwilliam Wild Flower Show, you could search by the numbers of the species at the show.</p>
                    <h3>Alternatively - search by name</h3>
                    <p>Try searching by a common name for instance. Searching for 'protea' would render more results than searching for 'king protea'.</p>
                    <div className="divider"></div>
                </div>
                
            </div>
        </div>


    )
}

export default Search