import React from 'react'
import Advert from './Advert'

function filterSponsors(sponsors, criteria) {
    // console.log(sponsors.advert)
    // console.log(criteria)
    let results = []

    sponsors.forEach(e => {
        let placesArr = e.advert.places ? e.advert.places : []
        placesArr.forEach(p => {

            if (criteria.pages && p.pages) {
                if (p.pages === criteria.pages) {
                    results = [...results, e]
                }
            }

            if (criteria.flowerspecies && p.flowerspecies) {
                if (p.flowerspecies.id === criteria.flowerspecies) {
                    results = [...results, e]
                }
            }

        });
    });

    results = results.slice(0, 3); //
    return results
}

const Sponsors = (props) => {

    let results = []
    if (props.sponsors.length > 0) {
        results = filterSponsors(props.sponsors, props.filterCriteria)
        // let fillerElement = props.sponsors[0] ?? {}

        // if (results.length < 3 && !props.filterCriteria.flowerspecies) {
        //     //console.log(fillerElement)
        //     for (let r = results.length; r < 3; r++) {
        //         let t = {...fillerElement}
        //         //console.log('r', r)
        //         t.id = 1000 + r
        //         //console.log(r, t)
        //         results = [...results, t]               
        //     }
        // } else {
        //     if (results.length === 0 && props.filterCriteria.flowerspecies) {
        //         let fillerElement = props.sponsors[0] ? props.sponsors[0] : {}
        //         fillerElement.id = 1000
        //         results = [fillerElement]
        //     }
        // }
    }

    //console.log(results.length)
    return (
        <div className="row sponsorsRow">
            <div className="divider"></div>
            {results.map(e => {
                return <Advert key={e.id} sponsor={e} columnSize={results.length} />
            })}
        </div>
    )
}

export default Sponsors