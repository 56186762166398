import React, { Component } from 'react'
import FlowerDetail from './FlowerDetail'
import SpeciesResultList from './SpeciesResultList'
import axios from 'axios';
import { BASE_URL, doSearchOnList } from './globalVars'
import Sponsors from './Sponsors'

class TextSearch extends Component {

    state = {
        flowerText: '',
        flowerShow: false,
        flowerElement: {},
        origflowerList: [],
        hideFlowerDetail: true,
        results: [],
        resultsHeader: '',
        searchDisabled: true
    }

    changeHandler = (e) => {
        let { name, value } = e.target
        this.setState({
            [name]: value,
            searchDisabled: (value.length < 3)
        })
    }

    searchNames = (t) => {
        let mayContainDuplicates = []

        let searchParams = [
            ["family_botanical_name", {"title":t}],
            ["familie_noemnaam", {"title":t}],
            ["family_common_name", {"title":t}],
            ["genus_botanical_name", {"title":t}],
            ["species_botanical_name", {"title":t}],
            ["genus_vernacular_name", {"title":t}],
            ["species_vernacular_name", {"title":t}]
        ]

        mayContainDuplicates = doSearchOnList(this.state.origflowerList, searchParams, false)
        let finalResults = [...new Set(mayContainDuplicates)]
        this.setState({
            flowerElement: {},
            resultsHeader: finalResults.length + ' results found for ' + t,
            results: finalResults
        })
    }

    showFlower = (i) => {
        let ii = i;
        let f = this.state.origflowerList
        let f2 = f.filter(e => e.id + '' === ii + '')

        if (f2.length === 1) {
            this.setState({
                results: [],
                flowerElement: f2[0],
                resultsHeader: ''
            })
        } else {
            this.setState({
                flowerElement: {},
                resultsHeader: ''
            })
        }
    }

    componentDidMount() {
        if (this.props.flowerList.length === 0) {
            axios.get(BASE_URL + '/flowerspecies.json')
                .then(res => {
                    if (res.status === 200) {
                        let fl = res.data.filter(e => e.region !== "")
                        this.setState({
                            origflowerList: fl,
                        })
                        localStorage.setItem('flowerspecies', JSON.stringify(fl))
                    }
                })
                .catch(e => {
                    let fl = JSON.parse(localStorage.getItem('flowerspecies'))
                    this.setState({
                        origflowerList: fl,
                    })
                })
        } else {
            this.setState({
                origflowerList: this.props.flowerList,
            })
        }
    }

    componentWillUnmount() {
        axios.CancelToken.source().cancel('axios call cancelled')
    }

    render() {
        window.scrollTo(0, 0);
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 align="left">Search by name(s)</h2>
                            {(!this.state.flowerElement.id) ?
                            <p align="left" style={{"fontSize":"110%"}}>Supplied text will be used to search through Family Botanical Name, Family Common Names, Genus Botanical Name, Genus Vernacular Name, Species Botanical Name, Species Vernacular Name as well as common names.</p> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6" style={{ "textAlign": "left" }}>
                            <input
                                type="text"
                                onChange={(e) => this.changeHandler(e)}
                                name="flowerText"
                                placeholder="Enter search term"
                                value={this.state.flowerText}
                                className="form-control form-control-lg"
                                style={{ "width": "66%", "height":"48px","display": "inline-block", "fontSize": "150%", "verticalAlign": "middle" }}
                            /><button className="btn btn-primary" disabled={this.state.searchDisabled} onClick={() => this.searchNames(this.state.flowerText)} style={{ "width": "33%", "textAlign":"center" }}>Search</button>
                        </div>
                    </div>
                    
                </div>

                <div className="container">
                        <div style={{ "paddingLeft": "15px", "paddingRight": "15px" }}><h3 align="left" style={{ "fontWeight": "bold" }}>{this.state.resultsHeader}</h3></div>
                    </div>
                    
                <div className="container">
                {
                    this.state.results.map(e => {
                        return (
                            <SpeciesResultList key={e.id}
                                    favourite={e}
                                    showFlower={this.showFlower}
                                />
                        )
                    })
                }

                </div>
                
                {this.state.flowerElement.id ?
                    <div className="container" style={{ "paddingLeft": "0px", "paddingRight": "0px" }}>
                        <FlowerDetail
                            hideBackBtn={true}
                            flower={this.state.flowerElement}
                            favourites={this.props.favourites}
                            toggleFavourites={this.props.toggleFavourites}
                            sponsors={this.props.sponsors}
                        />
                    </div>
                    :
                    <div className="container">
                            <div className="divider"></div>
                            <Sponsors
                                sponsors={this.props.sponsors}
                                filterCriteria={{ "pages": "search" }}
                            />
                        </div>
                }
            </div>

        )
    }

}

export default TextSearch