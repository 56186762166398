import React from 'react'

const Contact = () => {
    window.scrollTo(0, 0);
    return (
        <div className="container" style={{ "textAlign": "left" }}>
            <div className="row">
                <div className="col-sm-6">
                    <h2>Contact</h2>
                    <p>Contact us on the details below or follow us on social media</p>
                    <p>
                    <i className="fab fa-whatsapp-square"></i> <a href="https://wa.me/27768221600" target="_blank" rel="noreferrer" style={{ "color": "#444444" }}>076 822 1600</a>
                    </p>
                    <p>
                    <i className="fab fa-facebook-square"></i> <a href="https://www.facebook.com/Clan-Flora-104878797822734/?tn-str=k%2AF" target="_blank" rel="noreferrer" style={{ "color": "#444444" }}>follow us on facebook</a>
                    </p>
                    <p><i className="fas fa-envelope-square"></i> <a href="mailto:admin@clanflora.co.za" target="_blank" rel="noreferrer" style={{ "color": "#444444" }}>admin@clanflora.co.za</a></p>
                </div>
            </div>
        </div>
    )
}

export default Contact