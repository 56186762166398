const ZoomImage = (props) => {
    window.scrollTo(0, 0);

    return (<div style={{"marginTop":"-90px","minWidth":"100vh","top":"0","left":"0"}}>
        <div style={{"textAlign":"center"}}><button className="btn btn-primary"
             onClick={() => props.zoomImageClear()}
             style={{"width":"100%","height":"70px"}}
            >x Close image zoom x</button></div>
        <img src={props.zoomImage} alt="" style={{"minHeight":"100vh"}} />
        <div style={{"textAlign":"center"}}><button className="btn btn-primary"
             onClick={() => props.zoomImageClear()}
             style={{"width":"100%","height":"70px"}}
            >x Close image zoom x</button></div>
    </div>)
}

export default ZoomImage;




