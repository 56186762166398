import React from 'react'
import Flower from './Flower'
import SearchResultThumbnails from './SearchResultThumbnails'
import { doSearchOnList } from './globalVars'

const SearchResults = (props) => {
    let mayContainDuplicates = []
    let results = []
    
    let resultsThumbs = [];
    if (props.searchParams.length>0) {
        mayContainDuplicates = doSearchOnList(props.flowerList, props.searchParams)
        //console.log('mayContainDuplicates')
        results = [...new Set(mayContainDuplicates)]
        props.searchParams.forEach(i => {
            let v = i[1]
            resultsThumbs.push(v.title)
        });
    }

    let resultsHeader = results.length + ' species found for:';
    if (props.searchParams.length === 0 || results.length === 0) {
        resultsHeader = 'No results found'
    }
    results.sort((a,b) => {
        const nameA = a.family_botanical_name.toUpperCase(); 
        const nameB = b.family_botanical_name.toUpperCase(); 
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
    window.scrollTo(0, 0);

    return (
        <div style={{ "backgroundColor": "#ffffff", "minHeight": "60vh" }}>
            <div className="container">
                <h4>{resultsHeader}</h4>
                <SearchResultThumbnails resultsThumbs={resultsThumbs} />
                <div className="divider"></div>
                {results.length > 0 ?
                <div>Click on images below to view detail
                </div>
                : null }

                <div className="divider"></div>
                {
                    results.map(e => {
                        return (
                            <Flower key={e.id} info={e} showFlowerDetail={props.showFlowerDetail} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SearchResults