import React from 'react'

const ThumbnailImage = (props) => {

    return (
        <div>
            <div style={{"padding":"10px","fontSize":"160%", "color":"#2d7e99","backgroundColor":"#ffffff","fontWeight":"bold","textTransform":"capitalize"}}>{props.list.listTitle}</div>
            { props.list.listOptions ?

                props.list.listOptions.map((e) => {
                    let myStyle = (e.checked === true) ? 
                        { "opacity":"1", "border":"3px #85cc00 solid","width":"22%" } 
                        : 
                        { "opacity":"1", "border":"3px #ffffff solid","width":"22%" }

                    if (props.list.listId===3) {
                        myStyle = (e.checked === true) ? 
                        { "opacity":"1", "border":"3px #85cc00 solid","width":"44%" } 
                        : 
                        { "opacity":"1", "border":"3px #ffffff solid","width":"44%" }
                    } 

                    return (
                        <img 
                            key={props.list.listId + "_" + e.id}
                            onClick={() => props.toggleChoice(props.list.listId, e)}
                            src={"/choice_icons/"+e.title+".jpg"} 
                            alt={e.title} 
                            className="thumbnailImage" 
                            style={myStyle}
                        /> 
                    )
                })

                : null}
            <div className="divider"></div>
        </div>

    )
}

export default ThumbnailImage